import BANNER_MOBILE_01 from "./POST FEED ATENDIMENTO.png";
import BANNER_MOBILE_04 from "./BANNER_MOBILE_04.png";
import BANNER_MOBILE_17 from "./BANNER_MOBILE_VAI_DE_TURBOMOB.jpg";
import BANNER_MOBILE_REC from "./BANNER_MOBILE_17.png";
import BANNER_BLACK_FRIDAY from "./BANNER_BLACK_FRIDAY.png";
import BANNER_DEZEMBRO_MOBILE from "./BANNER_DEZEMBRO_MOBILE.png";
import BANNER_GIGA_MOBILE from "./BANNER-MOB-AGORA-E-GIGA-MOBILE.png";

export const BANNERS_MOBILE = [
  {
    image: BANNER_GIGA_MOBILE,
    alt: "banner-mobile",
    type: "mobile",
    link: "https://www.gigamaisfibra.com.br",
    target: "_blank"
  }
];
