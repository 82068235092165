import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HiMenuAlt3 } from "react-icons/hi";
import { IoCloseSharp } from "react-icons/io5";
import { AiOutlineUser } from "react-icons/ai";
import { BsTelephone } from "react-icons/bs";

import PopupCustomerArea from "pages/institutional/PopupCustomArea";

import { Analytics, Pixel } from "../../../../utils/analytics";

import Logo from "../../../../assets/images/logo_mob.svg";
import CartIcon from "../../../../assets/images/header-cart.svg";
import MenuItemIcon from "../../../../assets/images/header-menu-item-icon.svg";

import "./styles.css";

export default function MobileHeader() {
  const [menuVisible, setMenuVisible] = useState(false);

  useEffect(() => {
    Analytics.pageview(window.location.pathname + window.location.search);

    window.dataLayer.push({
      event: "pageview",
      page: {
        url: window.location.pathname,
      },
    });

    if (menuVisible) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
    }
  }, [menuVisible]);

  return (
    <>
      <header className="headerContainer">
        <nav>
          <a href="/" className="logo">
            <img src={Logo} alt="Logo Mob" />
          </a>

          <div className="buttons">
            <a
              href="https://ecommerce.assinemob.com.br/"
              className="contractNow"
              onClick={() => {
                Analytics.event({
                  category: "User",
                  action: "Iniciate checkout – botão Assine Já (Header mobile)",
                });

                Pixel.trackCustom("User", {
                  category: "User",
                  action: "Iniciate checkout – botão Assine Já (Header mobile)",
                });
              }}
            >
              ASSINE JÁ <img src={CartIcon} alt="Carrinho de compras" />
            </a>

            <HiMenuAlt3
              color="#000"
              size={30}
              onClick={() => setMenuVisible(true)}
            />
          </div>
        </nav>
      </header>

      {menuVisible && (
        <div className="menuOverlay">
          <div className="menu">
            <header>
              <img src={Logo} alt="Logo Mob" />
              <IoCloseSharp
                color="#DB0D15"
                size={30}
                onClick={() => setMenuVisible(false)}
              />
            </header>

            <div className="options">
              <button
                className="clientArea"
                onClick={() => window.open('https://central-assinante.mobtelecom.com.br/', '_blank')}
                style={{ cursor: "pointer" }}
              >
                ÁREA DO CLIENTE
                <AiOutlineUser className="iconButton" />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
