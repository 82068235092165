import React from "react";
import "./styles.css";

import {
  IdentificationIcon,
  MyProductIcon,
  PaymentIcon,
  SaleCompletedIcon,
} from "assets/svgs";

export default function ProgressBar({
  isIdentification,
  isPayment,
  isSaleCompleted,
}: {
  isIdentification?: boolean;
  isPayment?: boolean;
  isSaleCompleted?: boolean;
}) {
  return (
    <>
      <div className="progress-bar-container">
        <div className="progress-bar-content-icons">
          <div className="progress-bar-icon-container">
            <MyProductIcon fill="#F8F8F8" />
          </div>
          <p className="progress-bar-title">SEU PRODUTO</p>
          <div className="progress-bar-line-spaced" />
        </div>

        <div className="progress-bar-content-icons">
          <div
            className="progress-bar-icon-container"
            style={{
              borderColor:
                isIdentification === true ||
                isPayment === true ||
                isSaleCompleted === true
                  ? "#F8F8F8"
                  : "#A8A8A8",
              opacity:
                isIdentification === true ||
                isPayment === true ||
                isSaleCompleted === true
                  ? "1"
                  : "0.3",
            }}
          >
            <IdentificationIcon
              fill={
                isIdentification === true ||
                isPayment === true ||
                isSaleCompleted === true
                  ? "#F8F8F8"
                  : "#A8A8A8"
              }
              fill-opacity={
                isIdentification === true ||
                isPayment === true ||
                isSaleCompleted === true
                  ? "1"
                  : "0.3"
              }
            />
          </div>
          <p
            className="progress-bar-title"
            style={{
              color:
                isIdentification === true ||
                isPayment === true ||
                isSaleCompleted === true
                  ? "#F8F8F8"
                  : "#A8A8A8",
              opacity:
                isIdentification === true ||
                isPayment === true ||
                isSaleCompleted === true
                  ? "1"
                  : "0.3",
            }}
          >
            IDENTIFICAÇÃO
          </p>
          <div
            className="progress-bar-line-spaced"
            style={{
              borderColor:
                isIdentification === true ||
                isPayment === true ||
                isSaleCompleted === true
                  ? "#F8F8F8"
                  : "#A8A8A8",
              opacity:
                isIdentification === true ||
                isPayment === true ||
                isSaleCompleted === true
                  ? "1"
                  : "0.3",
            }}
          />
        </div>

        <div className="progress-bar-content-icons">
          <div
            className="progress-bar-icon-container"
            style={{
              borderColor:
                isPayment === true || isSaleCompleted === true
                  ? "#F8F8F8"
                  : "#A8A8A8",
              opacity:
                isPayment === true || isSaleCompleted === true ? "1" : "0.3",
            }}
          >
            <PaymentIcon
              fill={
                isPayment === true || isSaleCompleted === true
                  ? "#F8F8F8"
                  : "#A8A8A8"
              }
              stroke={
                isPayment === true || isSaleCompleted === true
                  ? "#F8F8F8"
                  : "#A8A8A8"
              }
              fill-opacity={
                isPayment === true || isSaleCompleted === true ? "1" : "0.3"
              }
            />
          </div>
          <p
            className="progress-bar-title"
            style={{
              color:
                isPayment === true || isSaleCompleted === true
                  ? "#F8F8F8"
                  : "#A8A8A8",
              opacity:
                isPayment === true || isSaleCompleted === true ? "1" : "0.3",
            }}
          >
            PAGAMENTO
          </p>
          <div
            className="progress-bar-line-spaced"
            style={{
              borderColor:
                isPayment === true || isSaleCompleted === true
                  ? "#F8F8F8"
                  : "#A8A8A8",
              opacity:
                isPayment === true || isSaleCompleted === true ? "1" : "0.3",
            }}
          />
        </div>

        <div className="progress-bar-content-icons">
          <div
            className="progress-bar-icon-container"
            style={{
              borderColor: isSaleCompleted === true ? "#F8F8F8" : "#A8A8A8",
              opacity: isSaleCompleted === true ? "1" : "0.3",
            }}
          >
            <SaleCompletedIcon
              fill={isSaleCompleted === true ? "#F8F8F8" : "#A8A8A8"}
              fill-opacity={isSaleCompleted === true ? "1" : "0.3"}
            />
          </div>
          <p
            className="progress-bar-title"
            style={{
              color: isSaleCompleted === true ? "#F8F8F8" : "#A8A8A8",
              opacity: isSaleCompleted === true ? "1" : "0.3",
            }}
          >
            CONFIRMAÇÃO
          </p>
        </div>
      </div>
      {/* MOBILE PROGRESS BAR */}
      <div className="progress-bar-container-mobile">
        <div
          className="progress-bar-icon-title-container-mobile"
          style={{
            display:
              isIdentification === true ||
              isPayment === true ||
              isSaleCompleted === true
                ? "none"
                : "flex",
          }}
        >
          <div className="progress-bar-content-icons-mobile">
            <div className="progress-bar-line-spaced-mobile" />
            <div className="progress-bar-icon-container-mobile">
              <MyProductIcon fill="#F8F8F8" />
            </div>
            <div className="progress-bar-line-spaced-mobile" />
          </div>
          <p className="progress-bar-title-mobile">SEU PRODUTO</p>
        </div>

        <div
          className="progress-bar-icon-title-container-mobile"
          style={{
            display: isIdentification === true ? "flex" : "none",
          }}
        >
          <div className="progress-bar-content-icons-mobile">
            <div className="progress-bar-line-spaced-mobile" />
            <div className="progress-bar-icon-container-mobile">
              <IdentificationIcon fill="#F8F8F8" />
            </div>
            <div className="progress-bar-line-spaced-mobile" />
          </div>
          <p className="progress-bar-title-mobile">IDENTIFICAÇÃO</p>
        </div>

        <div
          className="progress-bar-icon-title-container-mobile"
          style={{
            display: isPayment === true ? "flex" : "none",
          }}
        >
          <div className="progress-bar-content-icons-mobile">
            <div className="progress-bar-line-spaced-mobile" />
            <div className="progress-bar-icon-container-mobile">
            <PaymentIcon
              fill={
                isPayment === true || isSaleCompleted === true
                  ? "#F8F8F8"
                  : "#A8A8A8"
              }
              stroke={
                isPayment === true || isSaleCompleted === true
                  ? "#F8F8F8"
                  : "#A8A8A8"
              }
              fill-opacity={
                isPayment === true || isSaleCompleted === true ? "1" : "0.3"
              }
            />
            </div>
            <div className="progress-bar-line-spaced-mobile" />
          </div>
          <p className="progress-bar-title-mobile">PAGAMENTO</p>
        </div>

        <div
          className="progress-bar-icon-title-container-mobile"
          style={{
            display: isSaleCompleted === true ? "flex" : "none",
          }}
        >
          <div className="progress-bar-content-icons-mobile">
            <div className="progress-bar-line-spaced-mobile" />
            <div className="progress-bar-icon-container-mobile">
              <SaleCompletedIcon fill="#F8F8F8" />
            </div>
            <div className="progress-bar-line-spaced-mobile" />
          </div>
          <p className="progress-bar-title-mobile">CONFIRMAÇÃO</p>
        </div>
      </div>
    </>
  );
}
