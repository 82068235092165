import Get from "api/local/Get";

async function GetSaleStatus(contractId: string, queryType: string) {
  const result = await Get({
    url: `contracts/status?${queryType}=${contractId}`,
  });
  return result;
}

async function GetSaleStatusByContract(id: string) {
  const result = await Get({
    url: `contracts/status?contractId=${id}`,
  });
  return result;
}

export { GetSaleStatus, GetSaleStatusByContract };
