import React from "react";
import BackButton from "../BackButton";
import SubmitButton from "../SubmitButton";

import "./styles.css";

interface SubmitButtonsGroupProps {
  isLoading: boolean;
}

export default function SubmitButtonsGroup({
  isLoading,
}: SubmitButtonsGroupProps) {
  return (
    <div className="submitButtonContainer">
      <BackButton isLoading={isLoading} />
      <SubmitButton isLoading={isLoading} />
    </div>
  );
}
