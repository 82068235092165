import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import InputMask from "react-input-mask";
import { cpf, cnpj } from "cpf-cnpj-validator";
import Lottie from "lottie-react";
import Spinner from "../../assets/images/load-spinner.json";

import { GetSaleStatus, GetSaleStatusByContract } from "hooks/SaleStatus";

import DefaultModal from "components/Modal/Modal";
import { BackIconButtonWhite, ContinueIconButtonWhite } from "assets/svgs";
import { analytics } from "utils/firebaseAnalytics";
import { logEvent } from "firebase/analytics";

export default function CheckSaleStatusModal({
  modalVisible,
  setModalVisible,
}: {
  modalVisible: boolean;
  setModalVisible: (arg0: boolean) => void;
}) {
  const navigate = useNavigate();

  const filters = ["Número do contrato", "CPF/CNPJ"];

  const [isLoading, setIsLoading] = useState(false);
  const [errorInput, setErrorInput] = useState(false);
  const [isCfpInvalid, setIsCpfInvalid] = useState(false);
  const [hasNoSaleStatus, setHasNoSaleStatus] = useState(false);
  const [isNextContractSearchButton, setIsNextContractSearchButton] =
    useState(false);
  const [document, setDocument] = useState("");
  const [queryParams, setQueryParams] = useState("contractId");
  const [filterSelected, setFilterSelected] = useState("Número do contrato");
  const [saleStatusData, setSaleStatusData] = useState<any>([]);
  const [selectedContract, setSelectedContract] = useState<any>();
  const [saleStatusContractData, setSaleStatusContractData] = useState<any>();
  // 611.803.373-01
  async function fetchSaleStatus() {
    setErrorInput(false);
    setIsCpfInvalid(false);
    setHasNoSaleStatus(false);
    setIsLoading(true);

    if (!document) {
      setErrorInput(true);
      setIsLoading(false);
    } else if (
      !cpf.isValid(document) &&
      filterSelected === "CPF/CNPJ" &&
      document.length < 12
    ) {
      setIsCpfInvalid(true);
      setErrorInput(true);
      setIsLoading(false);
    } else if (
      !cnpj.isValid(document) &&
      filterSelected === "CPF/CNPJ" &&
      document.length > 11
    ) {
      setIsCpfInvalid(true);
      setErrorInput(true);
      setIsLoading(false);
    } else {
      try {
        setErrorInput(false);
        const response: any = await GetSaleStatus(document, queryParams);

        if (filterSelected === "Número do contrato") {
          // setSaleStatusContractData(response.data.data);
          navigate("/ecommerce/sale-status", {
            state: { saleStatusContractData: response.data.data },
          });
          setModalVisible(false);
        } else {
          setSaleStatusData(response.data.data);
          setIsNextContractSearchButton(true);
        }

        setIsLoading(false);
        // console.log(response.data.data);
      } catch (error) {
        setHasNoSaleStatus(true);
        setIsLoading(false);
      }
    }
  }

  async function fetchSaleStatusByContract() {
    setIsLoading(true);

    try {
      const response: any = await GetSaleStatusByContract(selectedContract);

      // setSaleStatusContractData(response.data?.data);
      navigate("/ecommerce/sale-status", {
        state: { saleStatusContractData: response.data.data },
      });
      setModalVisible(false);
      // console.log(response.data.data);
    } catch (error) {
      setIsLoading(false);
      setModalVisible(true);
      alert("Houve um problema ao buscar o status da venda.");
    }
  }

  useEffect(() => {
    if (filterSelected === "Número do contrato") {
      setQueryParams("contractId");
    } else {
      setQueryParams("document");
    }
  }, [filterSelected]);

  return (
    <DefaultModal
      isOpen={modalVisible}
      closeOnEsc
      showCloseIcon={false}
      onClose={() => setModalVisible(false)}
      classNames={{
        modal: "sale-status-modal",
        modalContainer: "sale-status-modal-container",
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (isNextContractSearchButton) {
            fetchSaleStatusByContract();
          } else {
            fetchSaleStatus();
          }
        }}
      >
        <h3 className="sale-status-modal-choose-contract-title">
          Acompanhe o status da sua internet
        </h3>

        <div className="sale-status-modal-filters-container">
          <div className="sale-status-modal-filters-content-container">
            {filters.map((filter) => (
              <div>
                <input
                  type="radio"
                  className="sale-status-modal-filter-input-radio"
                  id={filter}
                  name="filterType"
                  value={filter}
                  checked={filterSelected === filter}
                  onChange={(e) => {
                    setFilterSelected(e.currentTarget.value);
                    setErrorInput(false);
                    setHasNoSaleStatus(false);
                    setIsNextContractSearchButton(false);
                    setDocument("");
                    setSaleStatusData([]);
                    setSaleStatusContractData(null);
                  }}
                />
                <label
                  className="sale-status-modal-select-contract-label"
                  htmlFor={filter}
                >
                  {filter}
                </label>
              </div>
            ))}
          </div>

          {filterSelected === "Número do contrato" && (
            <>
              <div
                className="sale-status-input-container"
                style={{
                  backgroundColor: errorInput ? "rgba(236, 28, 36, 0.471)" : "",
                }}
              >
                {/* <div className="sale-status-input-icon">
              <HiOutlineUser color="#515151" size="1.25rem" />
            </div> */}

                <InputMask
                  mask=""
                  className="sale-status-input"
                  type="text"
                  autoFocus
                  placeholder={`Digite o ${filterSelected}`}
                  value={document}
                  onChange={(e) =>
                    setDocument(e.target.value.replace(/[^0-9]/g, ""))
                  }
                />
              </div>
            </>
          )}

          {filterSelected === "CPF/CNPJ" && (
            <>
              <div
                className="sale-status-input-container"
                style={{
                  backgroundColor: errorInput ? "rgba(236, 28, 36, 0.471)" : "",
                }}
              >
                {/* <div className="sale-status-input-icon">
              <HiOutlineUser color="#515151" size="1.25rem" />
            </div> */}

                <InputMask
                  mask={
                    document.length < 12
                      ? "999.999.999-999"
                      : "99.999.999/9999-99"
                  }
                  className="sale-status-input"
                  type="text"
                  autoFocus
                  placeholder={`Digite o ${filterSelected}`}
                  value={document}
                  onChange={(e) =>
                    setDocument(e.target.value.replace(/[^0-9]/g, ""))
                  }
                />
              </div>
              {isCfpInvalid && (
                <span className="sale-status-modal-choose-contract-error-input">
                  CPF/CNPJ inválido
                </span>
              )}
            </>
          )}

          {hasNoSaleStatus && (
            <span className="sale-status-modal-choose-contract-error-input">
              Nenhum status de venda foi encontrado.
            </span>
          )}
        </div>

        {saleStatusData.length > 0 && filterSelected === "CPF/CNPJ" && (
          <div className="sale-status-modal-choose-contract-container">
            <h3 className="sale-status-modal-title-choose-contract">
              CONTRATOS VINCULADOS AO CPF/CNPJ
            </h3>
            <h3 className="sale-status-modal-subtitle-choose-contract">
              Selecione um dos contratos para efetuar a verificação
            </h3>

            <div className="sale-status-modal-select-contract-container">
              <div className="sale-status-modal-select-contract-titles-container">
                <p className="sale-status-modal-select-contract-caption">
                  Nº do Contrato
                </p>
                {/* <p className="sale-status-modal-select-contract-caption">
              Valor do Plano
            </p>
            <p className="sale-status-modal-select-contract-caption">
              Descrição
            </p> */}
              </div>

              <div className="sale-status-modal-select-contract-content">
                {saleStatusData.map((contracts: any) => (
                  <label
                    className="sale-status-modal-select-contract-input-container"
                    htmlFor={contracts.idContrato}
                    style={{
                      backgroundColor:
                        selectedContract === contracts.idContrato
                          ? "rgba(236, 28, 36, 0.471)"
                          : "",
                    }}
                  >
                    <div className="sale-status-modal-choose-contract-input-caption-row">
                      <input
                        type="radio"
                        className="sale-status-modal-select-contract-input-radio"
                        id={contracts.idContrato}
                        name="selectContract"
                        value={contracts.idContrato}
                        onChange={(e) =>
                          setSelectedContract(e.currentTarget.value)
                        }
                      />
                      <p className="sale-status-modal-choose-contract-input-caption">
                        {contracts.idContrato}
                      </p>
                    </div>

                    {/* <p className="sale-status-modal-choose-contract-input-caption">
                  99,90
                </p>
                <p className="sale-status-modal-choose-contract-input-caption">
                  Wifi Turbinado
                </p> */}
                  </label>
                ))}
              </div>
            </div>
          </div>
        )}

        <div className="sale-status-modal-buttons">
          <button
            type="button"
            className="sale-status-back-button"
            onClick={() => setModalVisible(false)}
            disabled={isLoading}
          >
            <BackIconButtonWhite fill="#a80004" /> Voltar
          </button>

          {!isNextContractSearchButton ? (
            <button
              type="submit"
              className="sale-status-continue-button"
              // onClick={fetchSaleStatus}
              onClick={() => {
                logEvent(analytics, 'consulta do status da venda')
              }}
              disabled={isLoading}
            >
              {isLoading ? (
                <div className="sale-status-continue-button-react-loading">
                  <Lottie
                    animationData={Spinner}
                    loop={true}
                    style={{ width: "3rem", marginTop: 3 }}
                  />
                </div>
              ) : (
                <>
                  Continuar <ContinueIconButtonWhite fill="#FFFFFF" />
                </>
              )}
            </button>
          ) : (
            <button
              type="submit"
              className="sale-status-continue-button"
              // onClick={fetchSaleStatusByContract}
              disabled={isLoading}
            >
              {isLoading ? (
                <div className="sale-status-continue-button-react-loading">
                  <Lottie
                    animationData={Spinner}
                    loop={true}
                    style={{ width: "3rem", marginTop: 3 }}
                  />
                </div>
              ) : (
                <>
                  Continuar <ContinueIconButtonWhite fill="#FFFFFF" />
                </>
              )}
            </button>
          )}
        </div>
      </form>
    </DefaultModal>
  );
}
