import React from "react";
import { IoArrowUndoSharp } from "react-icons/io5";
import { useNavigate } from "react-router";

import "./styles.css";

interface BackButtonProps {
  isLoading: boolean;
}

export default function BackButton({ isLoading }: BackButtonProps) {
  const navigate = useNavigate();

  return (
    <button
      className="backButton"
      disabled={isLoading}
      onClick={() => navigate(-1)}
    >
      <IoArrowUndoSharp color="#A80004" size={20} /> Voltar
    </button>
  );
}
