import BANNER_WEB_01 from "./BANNER SITE PAGAMENTOS.png";
import BANNER_WEB_04 from "./BANNER_WEB_04.png";
import BANNER_WEB_17 from "./BANNER_WEB_VAI_DE_TURBOMOB.jpg";
import BANNER_WEB_REC from "./BANNER_WEB_17.png";
import BANNER_BLACK_FRIDAY from "./BANNER_BLACK_FRIDAY.png";
import BANNER_BLACK_FRIDAY_NEW from "./BANNER_BLACK_FRIDAY_NEW.png";
import BANNER_DEZEMBRO_WEB from "./BANNER_DEZEMBRO_WEB.png";
import BANNER_GIGA from "./BANNER-MOB-AGORA-E-GIGA-WEB.png";

export const BANNERS_WEB = [
  {
    image: BANNER_GIGA,
    alt: "banner-web",
    type: "web",
    link: "https://www.gigamaisfibra.com.br/",
    target: "_blank",
  }
];

// export * as BANNER_WEB_01 from "./BANNER_WEB_01.png";
// export * as BANNER_WEB_02 from "./BANNER_WEB_02.png";
// export * as BANNER_WEB_03 from "./BANNER_WEB_03.png";
// export * as BANNER_WEB_04 from "./BANNER_WEB_04.png";
// export * as BANNER_WEB_05 from "./BANNER_WEB_05.png";
