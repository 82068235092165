import React, { useEffect, useState } from "react";

import BannerHome from "../banner-home/banner-home";
import InstitucionalHeader from "../InstitucionalHeader";
import CheckSaleStatusModal from "components/CheckSaleStatusModal";
import Footer from "../footer/footer";

import ContractButtonIcon from "assets/images/home-options-contract-mob.svg";
import SuportButtonIcon from "assets/images/home-options-suport.svg";
import SecondInvoiceButtonIcon from "assets/images/home-options-second-invoice.svg";
import SaleStatusButtonIcon from "assets/images/home-options-sale-status.svg";
import CellphoneImage from "assets/images/minha-mob-cellphone.png";
import GooglePlayIcon from "assets/images/google-play-icon.svg";
import AppStoreIcon from "assets/images/app-store-icon.svg";
import ChangeAddressCardIcon from "assets/images/home-info-change-address.svg";
import ChangeDueDateCardIcon from "assets/images/home-info-change-due-date.svg";
import OwnershipCardIcon from "assets/images/home-info-change-ownership.svg";
import ScheduleVisitCardIcon from "assets/images/home-info-schedule-visit.svg";
import SecondInvoiceCardIcon from "assets/images/home-info-second-invoice.svg";
import FelixImage from "assets/images/felix.png";
import WifiIcon from "assets/images/home-benefits-wifi.svg";
import ComputerIcon from "assets/images/home-benefits-pc.svg";
import SettingsIcon from "assets/images/home-benefits-settings.svg";
import TelephoneIcon from "assets/images/home-benefits-telephone.svg";
import CartIcon from "../../../assets/images/header-cart.svg";

import "./institucional.css";

import { Analytics, Pixel } from "../../../utils/analytics";
import { logEvent } from "firebase/analytics";
import { analytics } from "utils/firebaseAnalytics";

export default function Institucional() {
  const [modalVisible, setModalVisible] = useState(false);


  useEffect(() => {
    logEvent(analytics, 'page_view', {
      page_location: window.location.pathname + window.location.search
    })

    Analytics.pageview(window.location.pathname + window.location.search);
    window.dataLayer.push({
      event: "pageview",
      page: {
        url: window.location.pathname,
      },
    });

    localStorage.clear();
  }, []);

  return (
    <>
      <InstitucionalHeader />
      <main className="institucionalMain">
        {/* Banner representativo */}
        <BannerHome />

        <div className="downloadApp">
          <div className="downloadAppContent">
            {/* <div className="cellphoneImage">
              <img src={CellphoneImage} alt="App Minha Mob" />
            </div> */}

            <div className="downloadInfo">
              <div className="title">
                <h2>A Mob Telecom agora é Giga+ Fibra!</h2>
                <div className="titleGroup">
                  <h3>
                  A nossa marca evoluiu e agora oferece mais possibilidades para você.
                  <br/>
                  <br/>
                  Bem-vindo à Giga+ Fibra: a Melhor Internet Gamer de 2023 dos Estados do Rio de Janeiro e Espírito Santo e de cidades em São Paulo e no Distrito Federal, segundo o Portal Melhor Plano.
                  <br/> 
                  <br/> 
                  Com a nossa internet você vai se conectar com mais qualidade e mais velocidade. São muito mais possibilidades: novos planos, mais benefícios e mais proximidade com nosso time de atendimento.
                  <br/>
                  <br/>
                  Clique abaixo e conheça o novo site, nele você encontra seu portal do cliente e todas as novidades da Giga+
                  <br/>
                  <br/>
                  </h3>
                  <hr/>
                </div>
                <a
                  href="https://www.gigamaisfibra.com.br/"
                  className="buttonGiga"
                  target="_blank"
                  onClick={() => {
                    Analytics.event({
                      category: "User",
                      action: "Iniciate checkout – Ir para o novo site",
                    });

                    logEvent(analytics, 'Ir para o novo site');                    

                    Pixel.trackCustom("User", {
                      category: "User",
                      action: "Iniciate checkout – Ir Para o novo site",
                    });
                  }}
                >
                  Ir para o novo site
                </a>
              </div>

              {/* <div className="downloadButtons">
                <a
                  href="https://play.google.com/store/apps/details?id=totalcross.appmmob"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    logEvent(analytics, 'Baixar o app minha mob pela Google Play')
                  }}
                >
                  <div className="iconContainer">
                    <img src={GooglePlayIcon} alt="Baixar App Android" />
                  </div>
                  <div className="text">
                    <span>Disponível no</span>
                    <br />
                    Google Play
                  </div>
                </a>

                <a
                  href="https://apps.apple.com/us/app/mob-central-do-assinante/id6480463268"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    logEvent(analytics, 'Baixar o app minha mob pela App Store')
                  }}
                >
                  <div className="iconContainer">
                    <img src={AppStoreIcon} alt="Baixar App IOS" />
                  </div>
                  <div className="text">
                    <span>Disponível no</span>
                    <br />
                    App Store
                  </div>
                </a>
              </div> */}
            </div>

            {/* <div className="cardsInfo">
              <div className="card">
                <div className="icon">
                  <img
                    src={SecondInvoiceCardIcon}
                    alt="Ícone de emissão da 2ª via"
                  />
                </div>
                <p className="description">Emissão de 2ª via de boleto</p>
              </div>

              <div className="card">
                <div className="icon">
                  <img
                    src={OwnershipCardIcon}
                    alt="Ícone de emissão da 2ª via"
                  />
                </div>
                <p className="description">Mudança de titularidade</p>
              </div>

              <div className="card">
                <div className="icon">
                  <img
                    src={ChangeAddressCardIcon}
                    alt="Ícone de emissão da 2ª via"
                  />
                </div>
                <p className="description">Mudança de endereço</p>
              </div>

              <div className="card">
                <div className="icon">
                  <img
                    src={ScheduleVisitCardIcon}
                    alt="Ícone de emissão da 2ª via"
                  />
                </div>
                <p className="description">Agendar visita técnica</p>
              </div>

              <div className="card">
                <div className="icon">
                  <img
                    src={ChangeDueDateCardIcon}
                    alt="Ícone de emissão da 2ª via"
                  />
                </div>
                <p className="description">Alterar vencimento do contrato</p>
              </div> */}
            {/* </div> */}
          </div>
        </div>

        {/* <div className="benefitsContainer">
          <div className="content">
            <div className="felixImage">
              <img src={FelixImage} alt="Felix" />
            </div>

            <div className="benefitsContent">
              <h2>
                CONHEÇA OS
                <br />
                <span>BENEFÍCIOS</span>
                <br />
                DE TER UMA INTERNET
                <br />
                <span>TURBINADA</span>
              </h2>

              <div className="cards">
                <div className="card">
                  <div className="icon">
                    <img src={WifiIcon} alt="Wifi" />
                  </div>

                  <div className="text">
                    <p>
                      Wi-fi de alta
                      <br />
                      performance
                    </p>
                  </div>
                </div>

                <div className="card">
                  <div className="icon">
                    <img src={ComputerIcon} alt="Computador" />
                  </div>

                  <div className="text">
                    <p>
                      Internet
                      <br />
                      100% fibra
                    </p>
                  </div>
                </div>

                <div className="card">
                  <div className="icon">
                    <img src={SettingsIcon} alt="Instalação" />
                  </div>

                  <div className="text">
                    <p>
                      Instalação
                      <br />
                      inclusa
                    </p>
                  </div>
                </div>

                <div className="card">
                  <div className="icon">
                    <img src={TelephoneIcon} alt="Telefone" />
                  </div>

                  <div className="text">
                    <p>
                      Atendimento
                      <br />
                      24 horas
                    </p>
                  </div>
                </div>

                <a
                  href="https://ecommerce.assinemob.com.br/"
                  className="contractNow"
                  onClick={() => {
                    Analytics.event({
                      category: "User",
                      action: "Iniciate checkout – Botão Assine Já",
                    });

                    logEvent(analytics, 'Botão Assine Já');                    

                    Pixel.trackCustom("User", {
                      category: "User",
                      action: "Iniciate checkout – Botão Assine Já",
                    });
                  }}
                >
                  ASSINE JÁ<img src={CartIcon} alt="Carrinho de compras" />
                </a>
              </div>
            </div> */}
          {/* </div> */}
        {/* </div> */}
      </main>
      <Footer />

      <CheckSaleStatusModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      />
    </>
  );
}
