import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AiFillCaretDown, AiOutlineUser } from "react-icons/ai";
import { BsTelephone } from "react-icons/bs";

import Logo from "../../../../assets/images/logo_mob.svg";
import CartIcon from "../../../../assets/images/header-cart.svg";
import MenuItemIcon from "../../../../assets/images/header-menu-item-icon.svg";

import PopupCustomerArea from "pages/institutional/PopupCustomArea";

import { Analytics, Pixel } from "../../../../utils/analytics";

import "./styles.css";
import { logEvent } from "firebase/analytics";
import { analytics } from "utils/firebaseAnalytics";

export default function DesktopHeader() {
  const [popupCustomerArea, setPopupCustomerArea] = useState(false);

  useEffect(() => {
    Analytics.pageview(window.location.pathname + window.location.search);

    window.dataLayer.push({
      event: "pageview",
      page: {
        url: window.location.pathname,
      },
    });
  }, []);

  return (
    <header>
      <nav>
        <div className="group">
          <a href="/">
            <img src={Logo} alt="Logo Mob" className="logo" />
          </a>
        </div>

        

        <div className="group">
          <a
            href="https://ecommerce.assinemob.com.br/"
            className="contractNow"
            onClick={() => {
              Analytics.event({
                category: "User",
                action: "Iniciate checkout – botão Assine Já (Header)",
              });

              logEvent(analytics, "botão Assine Já");

              Pixel.trackCustom("User", {
                category: "User",
                action: "Iniciate checkout – botão Assine Já (Header)",
              });
            }}
          >
            ASSINE JÁ
            <img src={CartIcon} alt="Carrinho de compras" />
          </a>

          <button
            className="clientArea"
            onClick={() => window.open('https://central-assinante.mobtelecom.com.br/', '_blank')}
            style={{ cursor: "pointer" }}
          >
            ÁREA DO CLIENTE
            <AiOutlineUser className="iconButton" />
          </button>
        </div>
      </nav>
    </header>
  );
}
