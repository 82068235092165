import { setUserProperties } from 'firebase/analytics';
import React from 'react';
import { analytics } from 'utils/firebaseAnalytics';

import './index.css';

interface ContainerProps {
    background: string;
    children: any;
    id: string;
    className: string;
}

export default function Container(props: ContainerProps) {
    setUserProperties(analytics, {
        user_id: props.id,
        category: 'User'
    })
    return (
        <div id={props.id} style={{ background: props.background }} className={`default-container-section ${props.className}`}>
            {props.children}
        </div>
    )
}