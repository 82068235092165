import ReactGA from "react-ga";
import ReactTA from "react-gtm-module";
import ReactPX from 'react-facebook-pixel';

ReactGA.initialize("G-FLGRM5WYMN");
ReactTA.initialize({
  gtmId: "GTM-5MWQB55"
});
ReactPX.init("3310117285705823", 
  {
    country: "BR",
    ct: "",
    db: "",
    em: "",
    fn: "",
    ge: "",
    ln: "",
    ph: "",
    st: "",
    zp: ""
  }, 
  {
    autoConfig: true, 
    debug: false
  });

export const Analytics = ReactGA; 
export const TagManager = ReactTA;
export const Pixel = ReactPX;
