import React from 'react';
import { Modal } from "react-responsive-modal";
import { DefaultModalProps } from "./types";
import "./styles.css";

import RedButton from "../Buttons/RedButton";
export default function DefaultModal(props: DefaultModalProps) {
  const {
    isOpen,
    onClose,
    text,
    title,
    primaryButton,
    secondaryButton,
    primaryHandleButton,
    secondaryHandleButton,
    doubleButton,
  } = props;

  return (
    <Modal
      styles={{
        modal: {
          maxWidth: 420,
        },
      }}
      open={isOpen}
      onClose={onClose}
      center
      closeIcon={false}
      closeOnEsc={true}
      classNames={{
        modal: "customModal",
      }}
    >
      <h3>{title}</h3>
      <p>{text}</p>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        {doubleButton && (
          <RedButton
            type={"button"}
            name={secondaryButton}
            handle={() => secondaryHandleButton()}
          />
        )}
        <RedButton
          type={"button"}
          name={primaryButton || ""}
          handle={() => primaryHandleButton()}
        />
      </div>
    </Modal>
  );
}
