import React from "react";

import "./styles.css";

interface CardContentStepsProps {
  handleFunction?: () => void;
}

type Props = CardContentStepsProps &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export default function CardContentSteps({ children }: Props) {
  return <div className="card-content-steps-body-content">{children}</div>;
}
