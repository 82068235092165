import Post from "../api/local/Post";
import Get from "../api/local/Get";

async function Prospect(body: object) {
  try {
    const result = await Post({
      url: "prospects",
      body,
    });
    return result;
  } catch (error) {
    throw error;
  }
}

async function ValidateData(document: string, dateBorn: string) {
  try {
    const result = await Get({
      url: `${process.env.REACT_APP_SECONDARY_API_URL}search-data`,
      query: {
        document,
        dateBorn
      }
    });

    return result;
  } catch (error) {
    throw error;
  }
}

export {
  Prospect,
  ValidateData
}
