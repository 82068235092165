import React from 'react';
import './labelInfo.css'

interface LabelInfo {
    title: string;
    text: string;
}

export default function LabelInfo(props: LabelInfo) {
    return (
        <div className='label-info--container'>
            <label className='label-info--title'>
                {props.title}
            </label>
            <span className='label-info--text'>
                {props.text}
            </span>
        </div>
    )
}