import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Routes from "./routes";
import reportWebVitals from "./reportWebVitals";
import "react-responsive-modal/styles.css";

//! Removido pois não está funcionando
// if (process.env.REACT_APP_ENV === "production") {
//   const src1 =
//     "https://d335luupugsy2.cloudfront.net/js/loader-scripts/9358ce0e-4d30-47b8-8b13-d75b094df2f9-loader.js";

//   const src2 =
//     "https://d335luupugsy2.cloudfront.net/js/rdstation-forms/stable/rdstation-forms.min.js";

//   const script1 = document.createElement("script");
//   script1.setAttribute("src", src1);

//   const script2 = document.createElement("script");
//   script2.setAttribute("src", src2);

//   document.getElementsByTagName("body")[0].appendChild(script1);
//   document.getElementsByTagName("body")[0].appendChild(script2);
// }

ReactDOM.render(
  <React.StrictMode>
    <Routes />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
