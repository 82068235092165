import React from "react";

import "./styles.css";

interface PageTitleProps {
  text: string;
}

export default function PageTitle({ text }: PageTitleProps) {
  return (
    <div className="pageTitleContainer">
      <h1>{text}</h1>
    </div>
  );
}
