import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AxiosError } from "axios";
import InputMask from "react-input-mask";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Footer from "../../../institutional/footer/footer";

import CardContentSteps from "components/CardContentSteps";
import { Prospect, ValidateData } from "hooks/Prospect";
import ModalEcommerce from "../../../../components/ModalEcommerce";
import ModalException from "components/ModalException";
import SaleHeader from "components/SaleHeader";
import ProgressBar from "components/ProgressBar";
import PageTitle from "components/PageTitle";
import SubmitButtonsGroup from "components/Form/SubmitButtonsGroup";

import { Analytics, Pixel } from "../../../../utils/analytics";

import "./styles.css";
import { logEvent } from "firebase/analytics";
import { analytics } from "utils/firebaseAnalytics";

export default function FormProspect() {
  const filters = ["Física", "Jurídica"];

  const [loading, setLoading] = useState(false);

  const [isStateRegistrationExempt, setIsStateRegistrationExempt] =
    useState(false);
  const [isFreeMunicipalRegistration, setIsFreeMunicipalRegistration] =
    useState(false);

  const [cellphone, setCellphone] = useState("");
  const [additionalPhone, setAdditionalPhone] = useState("");
  const [selectedPersonType, setSelectedPersonType] = useState("Física");

  const [errorAuthNextScreen, setErrorAuthNextScreen] =
    useState<boolean>(false);
  const [isOpenExceptionModal, setIsOpenExceptionModal] =
    useState<boolean>(false);

  const navigate = useNavigate();

  const schema = yup
    .object()
    .shape({
      nome:
        selectedPersonType === "Física"
          ? yup.string().required("Campo obrigatório")
          : yup.string(),
      razaoSocial:
        selectedPersonType === "Jurídica"
          ? yup.string().required("Campo obrigatório")
          : yup.string(),
      nomeMae:
        selectedPersonType === "Física"
          ? yup.string().required("Campo obrigatório")
          : yup.string(),
      dataNascimento:
        selectedPersonType === "Física"
          ? yup.string().required("Campo obrigatório")
          : yup.string(),
      // email: yup.string().email("E-mail Obrigatório"),
      telefoneFixo: yup.string(),
      telefoneAdicional: yup.string(),
      // telefoneMovel: yup.string().required("Campo obrigatório"),
      cpf:
        selectedPersonType === "Física"
          ? yup.string().required("Campo obrigatório")
          : yup.string(),
      rg:
        selectedPersonType === "Física"
          ? yup.string().required("Campo obrigatório")
          : yup.string(),
      sexo:
        selectedPersonType === "Física"
          ? yup.string().required("Campo obrigatório")
          : yup.string(),
      // tipoDeAtividade: yup.string().required("Tipo de Atividade Obrigatório"),
      inscricaoEstadual: isStateRegistrationExempt
        ? yup.string()
        : selectedPersonType === "Jurídica"
        ? yup.string().required("Campo obrigatório")
        : yup.string(),
      inscricaoMunicipal: isFreeMunicipalRegistration
        ? yup.string()
        : selectedPersonType === "Jurídica"
        ? yup.string().required("Campo obrigatório")
        : yup.string(),
      nomeFantasia:
        selectedPersonType === "Jurídica"
          ? yup.string().required("Campo obrigatório")
          : yup.string(),
      // tributacaoFederal: yup
      //   .string()
      //   .required("Tributação Federal Obrigatório"),
      cnpj:
        selectedPersonType === "Jurídica"
          ? yup.string().required("Campo obrigatório")
          : yup.string(),
      // cfop: yup.string().required("CFOP Obrigatório"),
      // areaDeAtuacao: yup.string().required("Área de Atuação Obrigatório"),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  function handleCloseModal() {
    setErrorAuthNextScreen(false);
    localStorage.clear();
    navigate("/");
  }

  async function handleSubmitApi(body: any) {
    try {
      // const idProspecto = localStorage.getItem("@mobEcommerce:idProspecto");
      const formInfo = localStorage.getItem("contact-form-info");

      let nameValid;

      // if (idProspecto) {
      //   if (selectedPersonType === "Jurídica") {
      //     return navigate("/ecommerce/sale/documentPj");
      //   } else {
      //     return navigate("/ecommerce/sale/documentPf");
      //   }
      // }

      let idCidade;
      if (formInfo) {
        idCidade = JSON.parse(formInfo).address.cityId;
      }

      setLoading(true);
      const formmatedDate = body.dataNascimento;
      const dataNova = formmatedDate.split("/");

      if (selectedPersonType === "Física") {
        await ValidateData(
          body.cpf,
          `${dataNova[2]}-${dataNova[1]}-${dataNova[0]}`
        )
          .then((response) => {
            if (
              !response.data.data.success &&
              response.data.data.success !== undefined
            ) {
              setLoading(false);
              setError("cpf", { message: "CPF inválido" });
              return;
            }

            setValue("nome", response.data.data.name);

            nameValid = response.data.data.name;
          })
          .catch((error: AxiosError) => {
            if (
              error.response?.data.message ===
              "Data de aniversário divergente do documento"
            ) {
              setLoading(false);
              setError("dataNascimento", { message: "Data inválida" });
              return;
            }
          });
      }

      const cpf = body?.cpf?.replace(/[^0-9]/g, "");
      const cnpj = body?.cnpj?.replace(/[^0-9]/g, "");
      const telefoneFixo = body?.telefoneFixo?.replace(/[^0-9]/g, "");
      const telefoneMovel = body.telefoneMovel.replace(/[^0-9]/g, "");
      const dataNascimento = body?.dataNascimento?.replace(/[^0-9]/g, "-");
      const nomeProspecto = body.nome.toUpperCase();

      const newBodyPhysicalPerson = {
        ...body,
        nome: nameValid || nomeProspecto,
        cpf,
        telefoneFixo: telefoneFixo ? telefoneFixo : telefoneMovel,
        telefoneMovel,
        dataNascimento,
        idSegmentoProspecto: 1,
        idTipoProspecto: 1,
        observacaoProspecto: "",
        cnpj: "",
        inscricaoEstadual: "",
        inscricaoMunicipal: "",
        nomeFantasia: "",
        tipoPessoa: "PessoaFisica",
        plataforma: "ecommerce",
        idCidade,
      };

      const newBodyLegalPerson = {
        ...body,
        nome: "",
        cpf: "",
        telefoneMovel,
        dataNascimento,
        idSegmentoProspecto: 1,
        idTipoProspecto: 1,
        observacaoProspecto: "",
        cnpj,
        inscricaoEstadual: isStateRegistrationExempt
          ? "ISENTO"
          : body?.inscricaoEstadual?.toUpperCase(),
        inscricaoMunicipal: isFreeMunicipalRegistration
          ? "ISENTO"
          : body?.inscricaoMunicipal?.toUpperCase(),
        tipoPessoa: "PessoaJurídica",
        plataforma: "ecommerce",
        idCidade,
      };

      const prospect: any = await Prospect(
        selectedPersonType === "Física"
          ? newBodyPhysicalPerson
          : newBodyLegalPerson
      );
      if (prospect.data.data?.idCliente) {
        localStorage.setItem(
          "@mobEcommerce:idCliente",
          prospect.data.data?.idCliente
        );
      }
      localStorage.setItem(
        "@mobEcommerce:idProspecto",
        prospect.data.data.idProspecto
      );
      localStorage.setItem(
        "@mobEcommerce:idEmpresa",
        prospect.data.data?.idEmpresa
      );
      localStorage.setItem("@mobEcommerce:prospectData", JSON.stringify(body));

      if (selectedPersonType === "Física") {
        Analytics.event({
          category: "User",
          action:
            "Identification checkout (dados pessoa física) - botão Continuar",
        });

        logEvent(analytics, 'Identification checkout (dados pessoa física) - botão Continuar')

        Pixel.trackCustom("User", {
          category: "User",
          action:
            "Identification checkout (dados pessoa física) - botão Continuar",
        });

        navigate("/ecommerce/sale/documentPf");
      } else {
        Analytics.event({
          category: "User",
          action:
            "Identification checkout (dados pessoa jurídica) - botão Continuar",
        });

        logEvent(analytics, 'Identification checkout (dados pessoa jurídica) - botão Continuar')

        Pixel.trackCustom("User", {
          category: "User",
          action:
            "Identification checkout (dados pessoa jurídica) - botão Continuar",
        });

        navigate("/ecommerce/sale/documentPj");
      }
    } catch (error: any) {
      if (
        error?.response?.data?.message === "CPF inválido" ||
        error?.response?.data?.message === "Documento inválido"
      ) {
        setLoading(false);
        return setError("cpf", { message: "CPF inválido" });
      } else if (
        error.response?.data.message ===
        "Data de aniversário divergente do documento"
      ) {
        setLoading(false);
        setError("dataNascimento", { message: "Data inválida" });
        return;
      } else {
        setLoading(false);
        setIsOpenExceptionModal(true);
      }
      setLoading(false);
    }
  }

  useEffect(() => {
    logEvent(analytics, 'page_view', {
      page_path: window.location.pathname + window.location.search
    })

    Analytics.pageview(window.location.pathname + window.location.search);
    window.dataLayer.push({
      event: "pageview",
      page: {
        url: window.location.pathname,
      },
    });
  }, []);

  useEffect(() => {
    const data = localStorage.getItem("contact-form-info");
    const prospectData = localStorage.getItem("@mobEcommerce:prospectData");
    if (data) {
      const form = JSON.parse(data);

      setValue("nome", form.name);
      setValue("telefoneMovel", form.phone);
      setCellphone(form.phone);
      setValue("email", form.email);
    }
    if (prospectData) {
      const data = JSON.parse(prospectData);

      setValue("nome", data.nome);
      setValue("nomeMae", data.nomeMae);
      setValue("telefoneMovel", data.telefoneMovel);
      setValue("telefoneFixo", data.telefoneFixo);
      setCellphone(data.telefoneMovel);
      setAdditionalPhone(data.telefoneFixo);
      setValue("email", data.email);
      setValue("cpf", data.cpf);
      setValue("rg", data.rg);
      setValue("dataNascimento", data.dataNascimento);
      setValue("sexo", data.sexo);
      setValue("razaoSocial", data.razaoSocial);
    }
  }, [setValue]);

  return (
    <>
      <SaleHeader />

      <PageTitle text="IDENTIFICAÇÃO" />

      <div className="ecommerce-sale-form-prospect-container">
        <div className="ecommerce-sale-form-prospect">
          <div className="card-body-portability-progress-bar-and-content">
            <ProgressBar isIdentification />
          </div>

          <CardContentSteps
            handleFunction={() => {
              navigate(-1);
            }}
          >
            <form
              className="ecommerce-sale-prospecto-form-container"
              onSubmit={handleSubmit(handleSubmitApi)}
            >
              <div className="ecommerce-sale-prospecto-person-type-container">
                <p className="ecommerce-sale-prospecto-person-type-title">
                  TIPO DE PESSOA
                </p>

                {filters.map((personType) => (
                  <div className="ecommerce-sale-prospecto-person-type-radio-container">
                    <input
                      type="radio"
                      className="ecommerce-sale-prospecto-person-type-input-radio"
                      id={personType}
                      name={personType}
                      value={personType}
                      checked={selectedPersonType === personType}
                      onChange={(e) => {
                        setSelectedPersonType(e.currentTarget.value);
                        if (personType === "Física") {
                          setValue("cpf", "");
                          setValue("nomeMae", "");
                        }
                        if (personType === "Jurídica") {
                          setValue("inscricaoEstadual", "");
                          setValue("inscricaoMunicipal", "");
                          setValue("razaoSocial", "");
                          setValue("cnpj", "");
                          setValue("telefoneAdicional", "");
                          setAdditionalPhone("");
                          setIsFreeMunicipalRegistration(false);
                          setIsStateRegistrationExempt(false);
                        }
                      }}
                    />
                    <label
                      htmlFor={personType}
                      className="ecommerce-sale-prospecto-person-type-label"
                    >
                      {personType}
                    </label>
                  </div>
                ))}
              </div>

              {selectedPersonType === "Física" ? (
                <div className="ecommerce-sale-prospecto-form">
                  <div className="ecommerce-sale-prospecto-input-container">
                    <label htmlFor="nome">
                      NOME COMPLETO<sup>*</sup>
                    </label>
                    <input
                      {...register("nome")}
                      className={`
                  ${errors.nome?.message && "inputError"}
                  ${loading && "inputAccept"}
                  `}
                    />

                    {errors.nome ? (
                      <p className="error">{errors.nome.message}</p>
                    ) : (
                      <></> // <p className="required">* obrigatório</p>
                    )}
                  </div>

                  <div className="ecommerce-sale-prospecto-input-container">
                    <label htmlFor="cpf">
                      DOCUMENTO CPF<sup>*</sup>
                    </label>
                    <InputMask
                      mask="999.999.999-99"
                      {...register("cpf")}
                      className={`
                  ${errors.cpf?.message && "inputError"}
                  ${loading && "inputAccept"}
                  `}
                    />

                    {errors.cpf ? (
                      <p className="error">{errors.cpf?.message}</p>
                    ) : (
                      <></> // <p className="required">* obrigatório</p>
                    )}
                  </div>

                  <div className="ecommerce-sale-prospecto-input-container">
                    <label htmlFor="telefoneAdicional">
                      TELEFONE ADICIONAL
                    </label>

                    <InputMask
                      mask="(99) 99999-9999"
                      inputRef={register("telefoneAdicional").ref}
                      value={additionalPhone}
                      onChange={(text) => {
                        setAdditionalPhone(text.target.value);
                        setValue("telefoneAdicional", text.target.value);
                      }}
                      className={`
                  ${errors.telefoneAdicional?.message && "inputError"}
                  ${loading && "inputAccept"}
                  `}
                    />

                    {errors.telefoneAdicional ? (
                      <p className="error">
                        {errors.telefoneAdicional?.message}
                      </p>
                    ) : (
                      <></> // <p className="required">* obrigatório</p>
                    )}
                  </div>

                  <div className="ecommerce-sale-prospecto-input-container">
                    <label htmlFor="dataNascimento">
                      DATA DE NASCIMENTO<sup>*</sup>
                    </label>

                    <InputMask
                      mask="99/99/9999"
                      {...register("dataNascimento")}
                      // className={`
                      // ${errors.dataNascimento?.message && "inputError"}
                      // ${loading && "inputAccept"}
                      // `}
                    />

                    {errors.dataNascimento ? (
                      <p className="error">{errors.dataNascimento?.message}</p>
                    ) : (
                      <></> // <p className="required">* obrigatório</p>
                    )}
                  </div>

                  <div className="ecommerce-sale-prospecto-input-container">
                    <label htmlFor="rg">
                      DOCUMENTO RG<sup>*</sup>
                    </label>
                    <input
                      {...register("rg")}
                      className={`
                  ${errors.rg?.message && "inputError"}
                  ${loading && "inputAccept"}
                  `}
                    />

                    {errors.rg ? (
                      <p className="error">{errors.rg?.message}</p>
                    ) : (
                      <></> // <p className="required">* obrigatório</p>
                    )}
                  </div>

                  <div className="ecommerce-sale-prospecto-input-container">
                    <label htmlFor="sexo">
                      SEXO<sup>*</sup>
                    </label>
                    <br />

                    <select
                      {...register("sexo")}
                      className={`
                  ecommerce-sale-prospect-input-container
                  ${errors.sexo?.message && "inputError"}
                  ${loading && "inputAccept"}
                  `}
                    >
                      <option value="">Selecione</option>
                      <option value="MASCULINO">MASCULINO</option>
                      <option value="FEMININO">FEMININO</option>
                    </select>

                    {errors.sexo ? (
                      <p className="error">{errors.sexo?.message}</p>
                    ) : (
                      <></> // <p className="required">* obrigatório</p>
                    )}
                  </div>

                  <div className="ecommerce-sale-prospecto-input-container">
                    <label htmlFor="telefoneMovel">
                      TELEFONE<sup>*</sup>
                    </label>
                    <br />

                    <InputMask
                      mask="(99) 99999-9999"
                      inputRef={register("telefoneMovel").ref}
                      value={cellphone}
                      onChange={(text) => {
                        setCellphone(text.target.value);
                        setValue("telefoneMovel", text.target.value);
                      }}
                      className={`
                  ${errors.telefoneMovel?.message && "inputError"}
                  ${loading && "inputAccept"}
                  `}
                    />

                    {errors.telefoneMovel ? (
                      <p className="error">{errors.telefoneMovel?.message}</p>
                    ) : (
                      <></> // <p className="required">* obrigatório</p>
                    )}
                  </div>

                  <div className="ecommerce-sale-prospecto-input-container">
                    <label htmlFor="email">
                      EMAIL<sup>*</sup>
                    </label>
                    <br />

                    <input
                      {...register("email")}
                      className={`
                  ${errors.email?.message && "inputError"}
                  ${loading && "inputAccept"}
                  `}
                    />

                    {errors.email ? (
                      <p className="error">{errors.email?.message}</p>
                    ) : (
                      <></> // <p className="required">* obrigatório</p>
                    )}
                  </div>

                  <div className="ecommerce-sale-prospecto-input-container">
                    <label htmlFor="nomeMae">
                      NOME DA MÃE<sup>*</sup>
                    </label>

                    <input
                      {...register("nomeMae")}
                      className={`
                  ${errors.nomeMae?.message && "inputError"}
                  ${loading && "inputAccept"}
                  `}
                    />

                    {errors.nomeMae ? (
                      <p className="error">{errors.nomeMae?.message}</p>
                    ) : (
                      <></> // <p className="required">* obrigatório</p>
                    )}
                  </div>

                  {/* <div className='ecommerce-sale-form-prospect-buttons'>
                    <div className='ecommerce-sale-form-prospect-buttons-back'>
                      <button onClick={() => navigate("/")}>Voltar</button>
                    </div>
                    <div className='ecommerce-sale-form-prospect-buttons-continue'>
                      <button>Continuar</button>
                    </div>
                  </div> */}

                  <div />
                </div>
              ) : (
                <div className="ecommerce-sale-prospecto-form">
                  <div className="ecommerce-sale-prospecto-input-container">
                    <label htmlFor="razaoSocial">
                      RAZÃO SOCIAL<sup>*</sup>
                    </label>
                    <input
                      {...register("razaoSocial")}
                      className={`
                  ${errors.razaoSocial?.message && "inputError"}
                  ${loading && "inputAccept"}
                  `}
                    />

                    {errors.razaoSocial ? (
                      <p className="error">{errors.razaoSocial.message}</p>
                    ) : (
                      <></> // <p className="required">* obrigatório</p>
                    )}
                  </div>

                  <div className="ecommerce-sale-prospecto-input-container">
                    <label htmlFor="tipoDeAtividade">
                      TIPO DE ATIVIDADE<sup>*</sup>
                    </label>

                    <select
                      {...register("tipoDeAtividade")}
                      className={`
                   ecommerce-sale-prospect-select
                  ${errors.tipoDeAtividade?.message && "inputError"}
                  ${loading && "inputAccept"}
                  `}
                    >
                      <option value="">Selecione</option>
                      <option value="MASCULINO">MASCULINO</option>
                      <option value="FEMININO">FEMININO</option>
                    </select>

                    {errors.tipoDeAtividade ? (
                      <p className="error">{errors.tipoDeAtividade?.message}</p>
                    ) : (
                      <></> // <p className="required">* obrigatório</p>
                    )}
                  </div>

                  <div className="ecommerce-sale-prospecto-input-container">
                    <label htmlFor="inscricaoEstadual">
                      INSCRIÇÃO ESTADUAL<sup>*</sup>
                    </label>
                    <input
                      type="number"
                      style={{
                        background: isStateRegistrationExempt
                          ? "#BEBEBE"
                          : "#FFFFFF",
                      }}
                      disabled={isStateRegistrationExempt}
                      {...register("inscricaoEstadual")}
                      className={`
                  ${errors.inscricaoEstadual?.message && "inputError"}
                  ${loading && "inputAccept"}
                  `}
                    />
                    <div className="ecommerce-sale-prospecto-person-type-checkbox-requery-container">
                      <div className="ecommerce-sale-prospecto-person-type-checkbox-container">
                        <input
                          id="inscricaoEstadualIsenta"
                          type="checkbox"
                          className="ecommerce-sale-prospecto-person-type-checkbox"
                          checked={isStateRegistrationExempt}
                          onChange={() => {
                            setIsStateRegistrationExempt(
                              !isStateRegistrationExempt
                            );
                            setValue("inscricaoEstadual", "");
                          }}
                        />
                        <label
                          htmlFor="inscricaoEstadualIsenta"
                          className="ecommerce-sale-prospecto-person-type-label"
                        >
                          <span>Isento</span>
                        </label>
                      </div>

                      {errors.inscricaoEstadual ? (
                        <p className="error">
                          {errors.inscricaoEstadual.message}
                        </p>
                      ) : (
                        <></> // <p className="required">* obrigatório</p>
                      )}
                    </div>
                  </div>

                  <div className="ecommerce-sale-prospecto-input-container">
                    <label htmlFor="nomeFantasia">
                      NOME FANTASIA<sup>*</sup>
                    </label>
                    <input
                      {...register("nomeFantasia")}
                      className={`
                  ${errors.nomeFantasia?.message && "inputError"}
                  ${loading && "inputAccept"}
                  `}
                    />

                    {errors.nomeFantasia ? (
                      <p className="error">{errors.nomeFantasia.message}</p>
                    ) : (
                      <></> // <p className="required">* obrigatório</p>
                    )}
                  </div>

                  <div className="ecommerce-sale-prospecto-input-container">
                    <label htmlFor="tributacaoFederal">
                      TRIBUTAÇÃO FEDERAL<sup>*</sup>
                    </label>

                    <select
                      {...register("tributacaoFederal")}
                      className={`
                   ecommerce-sale-prospect-select
                  ${errors.tributacaoFederal?.message && "inputError"}
                  ${loading && "inputAccept"}
                  `}
                    >
                      <option value="">Selecione</option>
                      <option value="MASCULINO">MASCULINO</option>
                      <option value="FEMININO">FEMININO</option>
                    </select>

                    {errors.tributacaoFederal ? (
                      <p className="error">
                        {errors.tributacaoFederal?.message}
                      </p>
                    ) : (
                      <></> // <p className="required">* obrigatório</p>
                    )}
                  </div>

                  <div className="ecommerce-sale-prospecto-input-container">
                    <label htmlFor="telefoneMovel">
                      TELEFONE<sup>*</sup>
                    </label>
                    <br />

                    <InputMask
                      mask="(99) 99999-9999"
                      inputRef={register("telefoneMovel").ref}
                      value={cellphone}
                      onChange={(text) => {
                        setCellphone(text.target.value);
                        setValue("telefoneMovel", text.target.value);
                      }}
                      className={`
                  ${errors.telefoneMovel?.message && "inputError"}
                  ${loading && "inputAccept"}
                  `}
                    />

                    {errors.telefoneMovel ? (
                      <p className="error">{errors.telefoneMovel?.message}</p>
                    ) : (
                      <></> // <p className="required">* obrigatório</p>
                    )}
                  </div>

                  <div className="ecommerce-sale-prospecto-input-container">
                    <label htmlFor="cnpj">
                      CNPJ<sup>*</sup>
                    </label>
                    <br />
                    <InputMask
                      mask="99.999.999/9999-99"
                      {...register("cnpj")}
                      className={`
                  ${errors.cnpj?.message && "inputError"}
                  ${loading && "inputAccept"}
                  `}
                    />

                    {errors.cnpj ? (
                      <p className="error">{errors.cnpj?.message}</p>
                    ) : (
                      <></> // <p className="required">* obrigatório</p>
                    )}
                  </div>

                  <div className="ecommerce-sale-prospecto-input-container">
                    <label htmlFor="cfop">
                      CFOP<sup>*</sup>
                    </label>
                    <br />

                    <select
                      {...register("cfop")}
                      className={`
                   ecommerce-sale-prospect-select
                  ${errors.cfop?.message && "inputError"}
                  ${loading && "inputAccept"}
                  `}
                    >
                      <option value="">Selecione</option>
                      <option value="MASCULINO">MASCULINO</option>
                      <option value="FEMININO">FEMININO</option>
                    </select>

                    {errors.cfop ? (
                      <p className="error">{errors.cfop?.message}</p>
                    ) : (
                      <></> // <p className="required">* obrigatório</p>
                    )}
                  </div>

                  <div className="ecommerce-sale-prospecto-input-container">
                    <label htmlFor="telefoneAdicional">
                      TELEFONE ADICIONAL
                    </label>

                    <InputMask
                      mask="(99) 99999-9999"
                      inputRef={register("telefoneAdicional").ref}
                      value={additionalPhone}
                      onChange={(text) => {
                        setAdditionalPhone(text.target.value);
                        setValue("telefoneAdicional", text.target.value);
                      }}
                      className={`
                  ${errors.telefoneAdicional?.message && "inputError"}
                  ${loading && "inputAccept"}
                  `}
                    />

                    {errors.telefoneAdicional ? (
                      <p className="error">
                        {errors.telefoneAdicional?.message}
                      </p>
                    ) : (
                      <></> // <p className="required">* obrigatório</p>
                    )}
                  </div>

                  <div className="ecommerce-sale-prospecto-input-container">
                    <label htmlFor="cfop">
                      ÁREA DE ATUAÇÃO<sup>*</sup>
                    </label>

                    <select
                      {...register("areaDeAtuacao")}
                      className={`
                   ecommerce-sale-prospect-select
                  ${errors.areaDeAtuacao?.message && "inputError"}
                  ${loading && "inputAccept"}
                  `}
                    >
                      <option value="">Selecione</option>
                      <option value="MASCULINO">MASCULINO</option>
                      <option value="FEMININO">FEMININO</option>
                    </select>

                    {errors.areaDeAtuacao ? (
                      <p className="error">{errors.areaDeAtuacao?.message}</p>
                    ) : (
                      <></> // <p className="required">* obrigatório</p>
                    )}
                  </div>

                  <div className="ecommerce-sale-prospecto-input-container">
                    <label htmlFor="inscricaoMunicipal">
                      INSCRIÇÃO MUNICIPAL<sup>*</sup>
                    </label>
                    <input
                      type="number"
                      style={{
                        background: isFreeMunicipalRegistration
                          ? "#BEBEBE"
                          : "#FFFFFF",
                      }}
                      disabled={isFreeMunicipalRegistration}
                      {...register("inscricaoMunicipal")}
                      className={`
                  ${errors.inscricaoMunicipal?.message && "inputError"}
                  ${loading && "inputAccept"}
                  `}
                    />

                    <div className="ecommerce-sale-prospecto-person-type-checkbox-requery-container">
                      <div className="ecommerce-sale-prospecto-person-type-checkbox-container">
                        <input
                          id="inscricaoMunicipalIsenta"
                          type="checkbox"
                          className="ecommerce-sale-prospecto-person-type-checkbox"
                          checked={isFreeMunicipalRegistration}
                          onChange={() => {
                            setIsFreeMunicipalRegistration(
                              !isFreeMunicipalRegistration
                            );
                            setValue("inscricaoMunicipal", "");
                          }}
                        />
                        <label
                          htmlFor="inscricaoMunicipalIsenta"
                          className="ecommerce-sale-prospecto-person-type-label"
                        >
                          <span>Isento</span>
                        </label>
                      </div>

                      {errors.inscricaoMunicipal ? (
                        <p className="error">
                          {errors.inscricaoMunicipal.message}
                        </p>
                      ) : (
                        <></> // <p className="required">* obrigatório</p>
                      )}
                    </div>
                  </div>

                  <div className="ecommerce-sale-prospecto-input-container">
                    <label htmlFor="email">
                      EMAIL<sup>*</sup>
                    </label>
                    <br />

                    <input
                      {...register("email")}
                      className={`
                  ${errors.email?.message && "inputError"}
                  ${loading && "inputAccept"}
                  `}
                    />

                    {errors.email ? (
                      <p className="error">{errors.email?.message}</p>
                    ) : (
                      <></> // <p className="required">* obrigatório</p>
                    )}
                  </div>

                  <div className="ecommerce-sale-prospecto-input-container">
                    <label htmlFor="rg">
                      DOCUMENTO RG<sup>*</sup>
                    </label>
                    <input
                      {...register("rg")}
                      className={`
                  ${errors.rg?.message && "inputError"}
                  ${loading && "inputAccept"}
                  `}
                    />

                    {errors.rg ? (
                      <p className="error">{errors.rg?.message}</p>
                    ) : (
                      <></> // <p className="required">* obrigatório</p>
                    )}
                  </div>

                  <div className="ecommerce-sale-prospecto-input-container">
                    <label htmlFor="cpf">
                      DOCUMENTO CPF<sup>*</sup>
                    </label>
                    <InputMask
                      mask="999.999.999-99"
                      {...register("cpf")}
                      className={`
                  ${errors.cpf?.message && "inputError"}
                  ${loading && "inputAccept"}
                  `}
                    />

                    {errors.cpf ? (
                      <p className="error">{errors.cpf?.message}</p>
                    ) : (
                      <></> // <p className="required">* obrigatório</p>
                    )}
                  </div>
                  <div />
                </div>
              )}
              <div style={{ position: "relative", bottom: -150 }}>
                <SubmitButtonsGroup isLoading={loading} />
              </div>
            </form>
          </CardContentSteps>
        </div>
      </div>

      {/* </CardBodyImage> */}
      <Footer />

      <ModalEcommerce
        isOpen={errorAuthNextScreen}
        onClose={() => handleCloseModal()}
        title={"ATENÇÃO!"}
        text={
          <>
            Nao conseguimos seguir com seu pedido, favor entre em contato no:
            0800 020 9000
          </>
        }
        primaryButton={"Ok"}
        secondaryButton={""}
        primaryHandleButton={() => handleCloseModal()}
        secondaryHandleButton={() => {}}
        doubleButton={false}
      />

      <ModalException
        isOpen={isOpenExceptionModal}
        onClose={() => setIsOpenExceptionModal(false)}
      />
    </>
  );
}
