import React from "react";
import Logo from "../../assets/images/logo_mob.svg";

import "./styles.css";

export default function SaleHeader() {
  return (
    <header>
      <div className="content">
        <a href="/">
          <img src={Logo} alt="Logo Mob" className="logo" />
        </a>
      </div>
    </header>
  );
}
