import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
apiKey: "AIzaSyBNRURual1Z12AfVkLGP6saY2Tg2633pis",
authDomain: "ecommerce-21f6e.firebaseapp.com",
projectId: "ecommerce-21f6e",
storageBucket: "ecommerce-21f6e.appspot.com",
messagingSenderId: "532016916033",
appId: "1:532016916033:web:248a799df87efa390b08c0",
measurementId: "G-HZFHRT379M"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
