import React from "react";
import "./styles.css";

import ReactLoading from "react-loading";

interface RedButtonProps {
  handle?: () => void;
  name: string;
  type?: "button" | "submit";
  isLoading?: boolean;
}

export default function RedButton({
  handle,
  name,
  type,
  isLoading,
}: RedButtonProps) {
  return (
    <button
      disabled={isLoading}
      type={type}
      className="button-sign-now button-sign-now-another"
      onClick={handle}
    >
      {isLoading ? (
        <ReactLoading type="bars" color="#DDDDDD" height={50} width={50} />
      ) : (
        name
      )}
    </button>
  );
}
