import axios, { AxiosResponse } from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axios.interceptors.response.use(
  async (response: AxiosResponse) => {
    const { config } = response;

    const payload = {
      log: {},
      referenceId: "",
      reference: "Logs Ecommerce",
      status:
        response.request.status === 400 || response.request.status === 500
          ? "WARNING"
          : "SUCCESS",
      file: "",
      line: "",
      endpoint: config.url || "",
      payload: response.config.data,
      response: response.data,
      application: "Ecommerce",
      connectionFail: "",
      namespace: "",
      function: "",
      params: "",
    };

    if (
      config.url &&
      !config.url.endsWith("/api/newLogs") &&
      !config.url.endsWith("https://old.mobtelecom.com.br/login/login.php") &&
      !config.url.endsWith(
        "https://cliente.mobtelecom.com.br/CentralAssinante/Mob/classes/funcoes.php"
      )
    ) {
      try {
        await api.post(
          `${process.env.REACT_APP_BASE_URL}/api/newLogs`,
          payload
        );
      } catch (error) {
        console.log("AUX Interceptor error: ", JSON.stringify(error, null, 2));
      }
    }

    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export { api };
