import React, { HTMLProps } from "react";
import Lottie from "lottie-react";
import { IoArrowRedoSharp } from "react-icons/io5";

import Spinner from "../../../assets/images/load-spinner.json";

import "./styles.css";
import { logEvent } from "firebase/analytics";
import { analytics } from "utils/firebaseAnalytics";

interface SubmitButtonProps extends HTMLProps<HTMLButtonElement> {
  isLoading?: boolean;
  isDisabled?: boolean;
}

export default function SubmitButton({
  isLoading = false,
  isDisabled = false,
  ...rest
}: SubmitButtonProps) {
  return (
    <button
      {...rest}
      type="submit"
      disabled={isLoading}
      className={isDisabled ? "continueButtonDisabled" : "continueButton"}
      onClick={() => {
        logEvent(analytics, 'Envio de formulário de assinatura')
      }}
    >
      {isLoading ? (
        <Lottie
          animationData={Spinner}
          loop={true}
          style={{ width: "3rem", marginTop: 3 }}
        />
      ) : (
        <>
          Continuar <IoArrowRedoSharp color="#ffffff" size={20} />
        </>
      )}
    </button>
  );
}
