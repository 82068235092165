
import React, { useEffect, useState } from 'react';
import {BANNERS_WEB} from '../../../assets/banners/web';
import {BANNERS_MOBILE} from '../../../assets/banners/mobile';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";

import "./styles.css";

export default function BannerHome() {
  const [banners, setBanners] = useState<any>([]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth < 768) {
        setBanners(BANNERS_MOBILE);
      } else {
        setBanners(BANNERS_WEB);
      }
    });

    return () => {
      window.removeEventListener('resize', () => {});
    }
  }, []);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setBanners(BANNERS_MOBILE);
    } else {
      setBanners(BANNERS_WEB);
    }
  }, [window.innerWidth])

  return (
    //disableButtonsControls disableDotsControls
    <div className="banner-home">
      <AliceCarousel autoPlay keyboardNavigation disableButtonsControls disableDotsControls>
        {
          banners.map((image: any) => (
            <a href={image.link} target={image.target}>
              <img src={image.image} alt={image.alt} />
            </a>
          ))
        }
      </AliceCarousel>
    </div>
  );
}