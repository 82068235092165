import React from 'react'
import { Modal } from 'react-responsive-modal';
import { DefaultModalProps } from './types';

export default function DefaultModal (props: DefaultModalProps) {

    const {
        children,
        isOpen, 
        onClose,
        showCloseIcon,
        closeOnEsc,
        classNames,
        closeOnOverlayClick
    } = props;

    return (
        <Modal 
            open={isOpen} 
            onClose={onClose}
            center
            closeOnEsc={closeOnEsc}
            showCloseIcon={showCloseIcon}
            classNames={classNames}
            closeOnOverlayClick={closeOnOverlayClick}
        >
            {children}
        </Modal>
    )
}