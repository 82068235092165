import React from "react";
import { Modal } from "react-responsive-modal";
import "./styles.css";
import { ModalExceptionProps } from "./type";

import serverProblemaGif from "../../assets/images/server-problem.gif";

export default function ModalException(props: ModalExceptionProps) {
  const { isOpen, onClose } = props;

  const closeIcon = (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22 0H0V22H22V0ZM16.2873 6.08667C16.3678 5.99467 16.4291 5.88751 16.4675 5.77149C16.506 5.65546 16.5209 5.53292 16.5112 5.41107C16.5016 5.28922 16.4678 5.17052 16.4116 5.06195C16.3555 4.95338 16.2782 4.85715 16.1843 4.7789C16.0904 4.70065 15.9818 4.64197 15.8648 4.60631C15.7479 4.57065 15.6251 4.55873 15.5035 4.57126C15.3819 4.58379 15.264 4.6205 15.1568 4.67925C15.0497 4.73799 14.9553 4.81758 14.8793 4.91333L11 9.56817L7.12113 4.91333C7.04469 4.81895 6.95027 4.74071 6.84333 4.68313C6.7364 4.62556 6.61909 4.5898 6.49823 4.57795C6.37736 4.5661 6.25534 4.57838 6.13926 4.61409C6.02318 4.6498 5.91536 4.70821 5.82204 4.78595C5.72873 4.86368 5.6518 4.95918 5.59571 5.0669C5.53962 5.17462 5.5055 5.29241 5.49532 5.41343C5.48515 5.53445 5.49912 5.65629 5.53643 5.77186C5.57374 5.88743 5.63365 5.99444 5.71267 6.08667L9.80696 11L5.71267 15.9133C5.63219 16.0053 5.57092 16.1125 5.53246 16.2285C5.49401 16.3445 5.47915 16.4671 5.48875 16.5889C5.49835 16.7108 5.53223 16.8295 5.58839 16.938C5.64455 17.0466 5.72185 17.1429 5.81575 17.2211C5.90965 17.2994 6.01825 17.358 6.13516 17.3937C6.25207 17.4294 6.37494 17.4413 6.49652 17.4287C6.61811 17.4162 6.73596 17.3795 6.84315 17.3208C6.95034 17.262 7.04469 17.1824 7.12067 17.0867L11 12.4318L14.8793 17.0867C14.9553 17.1824 15.0497 17.262 15.1568 17.3208C15.264 17.3795 15.3819 17.4162 15.5035 17.4287C15.6251 17.4413 15.7479 17.4294 15.8648 17.3937C15.9818 17.358 16.0904 17.2994 16.1843 17.2211C16.2782 17.1429 16.3555 17.0466 16.4116 16.938C16.4678 16.8295 16.5016 16.7108 16.5112 16.5889C16.5209 16.4671 16.506 16.3445 16.4675 16.2285C16.4291 16.1125 16.3678 16.0053 16.2873 15.9133L12.193 11L16.2873 6.08667Z"
        fill="#DB0D15"
      />
    </svg>
  );
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      center
      closeIcon={closeIcon}
      closeOnEsc={true}
      classNames={{ closeButton: "modal-exception-close-icon-button" }}
    >
      <div className="modal-exception-container">
        <div className="modal-exception-content-container">
          <h3 className="modal-exception-text-top">Oh não! ..</h3>

          <img
            src={serverProblemaGif}
            alt="Imagem problemas em nossos servidores."
            className="modal-exception-error-image"
          />

          <div className="modal-exception-text-bottom-container">
            <h3 className="modal-exception-text-bottom">
              Estamos com problemas em nossos servidores.
            </h3>
            <h3 className="modal-exception-text-bottom">
              Tente novamente em alguns instantes.
            </h3>
          </div>

          <button
            type="button"
            className="modal-exception-button"
            onClick={() => onClose()}
          >
            OK
          </button>
        </div>
      </div>
    </Modal>
  );
}
