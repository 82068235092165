import { PostProps } from "api/types";
import { api } from "../index";

export default async function Post({ url, body }: PostProps) {
  try {
    const data = await api.post(url, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (error) {
    throw error;
  }
}
