import React from "react";
import {
  BrowserRouter,
  Routes as Switch,
  Route,
  Outlet,
} from "react-router-dom";

import Home from "pages/institutional";
import Portability from "pages/ecommerce/Portability";
import FormProspect from "pages/ecommerce/Sale/FormProspect";
import ScheduleInstall from "pages/ecommerce/ScheduleInstall";
import SaleCompleted from "pages/ecommerce/SaleCompleted";
import Address from "pages/ecommerce/Sale/Address";
import TermsPayments from "pages/ecommerce/Sale/TermsPayments";
import TypePayments from "pages/ecommerce/Sale/TypePayments";
import PortabilityConfirm from "pages/ecommerce/Portability/PortabilityConfirm";
import OutOfLocation from "pages/ecommerce/OutOfLocation";
import { SaleStatusCompleted } from "pages/ecommerce/SaleStatusCompleted";
import MapContainer from "pages/institutional/map/";
import Institucional from "pages/institutional/institucional/Institucional";
import MyAccount from "pages/institutional/account/account";
import QuemSomos from "pages/institutional/quem-somos/QuemSomos";
import SegundaVia from "pages/institutional/segunda-via/SegundaVia";
import SuporteTecnico from "pages/institutional/servico-tecnico/ServicoTecnico";
import InvalidPropspect from "pages/ecommerce/InvalidProspect";
import Etica from "pages/institutional/etica/Etica";
import CanalEtica from "pages/institutional/canal-etica";
import NossasLojas from "pages/institutional/nossas-lojas/NossasLojas";
import PoliticaPrivacidade from "pages/institutional/politica-privacidade/PoliticaPrivacidade";
import FaleConosco from "pages/institutional/fale-conosco/FaleConosco";
import SaleStatus from "pages/ecommerce/SaleStatus";
import SendDocumentPj from "pages/ecommerce/Sale/SendDocumentPj";
import SendDocumentPf from "pages/ecommerce/Sale/SendDocumentPf";
import Maintenance from "pages/ecommerce/Maintenance";
import { Rqual } from "components/Rqual";
import { Normativo } from "components/Normativo";
import { Satisfaction } from "components/Satisfaction";
// import Welcome from "pages/ecommerce/Welcome";

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        {/*Routes Institutional*/}
        <Route path="/" element={<Institucional />} />
        
        {/* Rotas sem redirecionamento */}
        <Route
          path="/out-location"
          element={<RedirectToExternal url="https://www.gigamaisfibra.com.br/" />}
        />
        <Route
          path="/maintenance"
          element={<RedirectToExternal url="https://www.gigamaisfibra.com.br/" />}
        />
        <Route
          path="/invalid-prospect"
          element={<RedirectToExternal url="https://www.gigamaisfibra.com.br/" />}
        />
        <Route
          path="/map"
          element={<RedirectToExternal url="https://www.gigamaisfibra.com.br/" />}
        />
        {/*Routes E-Commerce*/}
        <Route path="ecommerce" element={<Outlet />}>
          <Route 
            index
            element={<RedirectToExternal url="https://www.gigamaisfibra.com.br/" />}
          />
          <Route
            path="portability"
            element={<RedirectToExternal url="https://www.gigamaisfibra.com.br/" />}
          />
          <Route
            path="portability-confirm"
            element={<RedirectToExternal url="https://www.gigamaisfibra.com.br/" />}
          />
          <Route
            path="intermediate-sale"
            element={<RedirectToExternal url="https://www.gigamaisfibra.com.br/" />}
          />
          <Route
            path="sale-status"
            element={<RedirectToExternal url="https://www.gigamaisfibra.com.br/" />}
          />
          {/* <Route path="welcome" element={<Welcome step="welcome" />} /> */}
          <Route
            path="sale"
            element={<RedirectToExternal url="https://www.gigamaisfibra.com.br/" />}
          >
            <Route index element={<FormProspect />} />
            <Route path="documentPf" />
            <Route path="documentPj" element={<RedirectToExternal url="https://www.gigamaisfibra.com.br/" />} />
            <Route path="address" element={<RedirectToExternal url="https://www.gigamaisfibra.com.br/" />} />
            <Route path="payment" element={<RedirectToExternal url="https://www.gigamaisfibra.com.br/" />} />
            <Route path="paymenttype" element={<RedirectToExternal url="https://www.gigamaisfibra.com.br/" />} />
            <Route path="install" element={<RedirectToExternal url="https://www.gigamaisfibra.com.br/" />} />
            {/* <Route path="finnaly" element={<Welcome step="finnaly" />} /> */}
            <Route path="completed" element={<RedirectToExternal url="https://www.gigamaisfibra.com.br/" />} />
          </Route>
        </Route>
        {/* <Route path="*" element={<span>Not Found!<span/>} /> */}
        <Route
          path="/segunda-via"
          element={<RedirectToExternal url="https://www.gigamaisfibra.com.br/" />}
        />
        <Route
          path="/suporte-tecnico"
          element={<RedirectToExternal url="https://www.gigamaisfibra.com.br/" />}
        />
        <Route
          path="/etica-integridade"
          element={<RedirectToExternal url="https://www.gigamaisfibra.com.br/" />}
        />
        <Route 
          path="/canal-etica"
          element={<RedirectToExternal url="https://www.gigamaisfibra.com.br/" />}
        />
        
        {/* Rotas redirecionadas */}
        <Route
          path="/institucional"
          element={<RedirectToExternal url="https://www.gigamaisfibra.com.br/" />}
        />
        <Route path="/account" element={<MyAccount />} />
        <Route
          path="/quem-somos"
          element={<RedirectToExternal url="https://www.gigamaisfibra.com.br/quem-somos/" />}
        />
        <Route
          path="/nossas-lojas"
          element={<RedirectToExternal url="https://www.gigamaisfibra.com.br/onde-estamos/" />}
        />
        <Route
          path="/normativo"
          element={<RedirectToExternal url="https://www.gigamaisfibra.com.br/contratos-e-regulamentos/" />}
        />
        <Route
          path="/politica-privacidade"
          element={<RedirectToExternal url="https://www.gigamaisfibra.com.br/politica-de-privacidade/" />}
        />
        <Route
          path="/fale-conosco"
          element={<RedirectToExternal url="https://www.gigamaisfibra.com.br/fale-conosco/" />}
        />
        <Route
          path="/rqual"
          element={<RedirectToExternal url="https://www.gigamaisfibra.com.br/" />}
        />
        <Route
          path="/satisfacao"
          element={<RedirectToExternal url="https://www.gigamaisfibra.com.br/" />}
        />

        {/* urls que não existia no site */}
        <Route
          path="/unidades"
          element={<RedirectToExternal url="https://www.gigamaisfibra.com.br/onde-estamos/" />}
        />
        <Route
          path="/area-do-cliente"
          element={<RedirectToExternal url="https://www.gigamaisfibra.com.br/" />}
        />
        <Route
          path="/wholesale"
          element={<RedirectToExternal url="https://www.gigamaisfibra.com.br/" />}
        />
        {/* <Route
          path="/documents/normativo.pdf"
          element={<Normativo />}
        /> */}
      </Switch>
    </BrowserRouter>
  );
}

const RedirectToExternal = ({ url }: { url: string }) => {
  React.useEffect(() => {
    window.location.href = url;
  }, [url]);

  return null;
};

export default Routes;
