import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import face from "../../../assets/images/face.png";
import insta from "../../../assets/images/insta.png";
import youtube from "../../../assets/images/youtube.png";
import logoFooterGrayLeft from "../../../assets/images/logo-footer-gray-left.svg";
import logoFooterRedLeft from "../../../assets/images/logo-footer-red-left.svg";
import logoFooterGrayCenter from "../../../assets/images/logo-footer-gray-center.svg";
import logoFooterRedRightTop from "../../../assets/images/logo-footer-red-right-top.svg";
import logoFooterRedRightBottom from "../../../assets/images/logo-footer-red-right-bottom.svg";

import Contrato_de_Fidelidade_Razão_DB3_marca_GIGA_MAIS_FIBRA from "../../../assets/documents/Contrato_de_Fidelidade_Razão_DB3_marca_GIGA_MAIS_FIBRA.pdf";
import Contrato_SCM_Razao_DB3_Marca_GIGA_MAIS_FIBRA from "../../../assets/documents/Contrato_SCM_Razao_DB3_Marca_GIGA_MAIS_FIBRA.pdf";

import "./styles.css";
import { analytics } from "utils/firebaseAnalytics";
import { logEvent } from "firebase/analytics";

export default function Footer() {
  const [showMore1, setShowMore1] = useState(true);
  const [showMore2, setShowMore2] = useState(true);
  const [showMore3, setShowMore3] = useState(true);
  const [showMore4, setShowMore4] = useState(true);

  useEffect(() => {
    if (showMore1 === true) {
      const height: any = document.getElementsByClassName("col-contain");
      height[0].style.height = "0";
      height[0].style.display = "none";
    } else {
      const height: any = document.getElementsByClassName("col-contain");
      height[0].style.height = "130px";
      height[0].style.display = "flex";
    }
  }, [showMore1]);

  useEffect(() => {
    if (showMore2 === true) {
      const height: any = document.getElementsByClassName("col-contain");
      height[1].style.height = "0";
      height[1].style.display = "none";
    } else {
      const height: any = document.getElementsByClassName("col-contain");
      height[1].style.height = "130px";
      height[1].style.display = "flex";
    }
  }, [showMore2]);

  useEffect(() => {
    if (showMore3 === true) {
      const height: any = document.getElementsByClassName("col-contain");
      height[2].style.height = "0";
      height[2].style.display = "none";
    } else {
      const height: any = document.getElementsByClassName("col-contain");
      height[2].style.height = "130px";
      height[2].style.display = "flex";
    }
  }, [showMore3]);

  useEffect(() => {
    if (showMore4 === true) {
      const height: any = document.getElementsByClassName("col-contain");
      height[3].style.height = "0";
      height[3].style.display = "none";
    } else {
      const height: any = document.getElementsByClassName("col-contain");
      height[3].style.height = "80px";
      height[3].style.display = "flex";
    }
  }, [showMore4]);

  return (
    <div className="footer">
      <div className="footer-top">
        <div className="col-footer">
          <div>
            <h3 className="title-col">Institucional</h3>
            <button
              className="btn-footer"
              onClick={() => setShowMore1(!showMore1)}
              type="button"
            >
              <h3>Institucional</h3>
            </button>
            <div className="col-contain">
              <a
                href="https://institucional.mobtelecom.com.br/historia-mob"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  logEvent(analytics, "Acesso Quem Somos");
                }}
              >
                Quem Somos
              </a>
              <a
                href="https://institucional.mobtelecom.com.br/etica-integridade"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  logEvent(analytics, "Acesso Ética e Integridade");
                }}
              >
                Ética e Integridade
              </a>

            </div>
            <div className="col-contain-desk">
              <a
                href="https://institucional.mobtelecom.com.br/historia-mob"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  logEvent(analytics, "Acesso Quem Somos");
                }}
              >
                Quem Somos
              </a>
              <a
                href="https://institucional.mobtelecom.com.br/etica-integridade"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  logEvent(analytics, "Acesso Ética e Integridade");
                }}
              >
                Ética e Integridade
              </a>
            </div>
          </div>
        </div>
        <div className="col-footer">
          <div>
            <h3 className="title-col">Atendimento</h3>
            <button
              className="btn-footer"
              onClick={() => setShowMore2(!showMore2)}
              type="button"
            >
              <h3>Atendimento</h3>
            </button>
            <div className="col-contain">
              <a
                href="https://suporte-mobdigital.com.br/faq"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  logEvent(analytics, "Acesso FAQ");
                }}
              >
                FAQ{" "}
              </a>
              <Link
                to="/fale-conosco"
                onClick={() => {
                  logEvent(analytics, "Acesso Fale Conosco");
                }}
              >
                Fale Conosco
              </Link>
              <a
                href="https://allohafibra.gupy.io/ "
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  logEvent(analytics, "Acesso Trabalhe Conosco");
                }}
              >
                Trabalhe Conosco
              </a>
            </div>
            <div className="col-contain-desk">
              <a
                href="https://suporte-mobdigital.com.br/faq"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  logEvent(analytics, "Acesso FAQ");
                }}
              >
                FAQ{" "}
              </a>
              <Link
                to="/fale-conosco"
                onClick={() => {
                  logEvent(analytics, "Acesso Fale Conosco");
                }}
              >
                Fale Conosco
              </Link>
              <a
                href="https://allohafibra.gupy.io/ "
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  logEvent(analytics, "Acesso Trabalhe Conosco");
                }}
              >
                Trabalhe Conosco
              </a>
            </div>
          </div>
        </div>
        <div className="col-footer">
          <div>
            <h3 className="title-col">Serviços Online</h3>
            <button
              className="btn-footer"
              onClick={() => setShowMore3(!showMore3)}
              type="button"
            >
              <h3>Serviços Online</h3>
            </button>
            <div className="col-contain">
              <a
                href="https://institucional.mobtelecom.com.br/politica-privacidade"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  logEvent(analytics, "Acesso Política de Privacidade");
                }}
              >
                Política de Privacidade
              </a>
            </div>
            <div className="col-contain-desk">
              <a
                href="https://institucional.mobtelecom.com.br/politica-privacidade"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  logEvent(analytics, "Acesso Política de Privacidade");
                }}
              >
                Política de Privacidade
              </a>
            </div>
          </div>
        </div>
        <div className="col-footer">
          <div>
            <div
              className="col-contain"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
            </div>
          </div>
        </div>
        <img
          className="footerLogoMob"
          src={logoFooterRedLeft}
          alt="Logo Mob"
          style={{ position: "absolute", width: 50, height: 50, left: 20 }}
        />
        <img
          className="footerLogoMob"
          src={logoFooterRedRightTop}
          alt="Logo Mob"
          style={{ position: "absolute", width: 50, height: 50, right: 40 }}
        />
      </div>
      <div className="footer-bottom">
        <div className="footer-bottom-row">
          <div className="text-footer">
            <span>
              Copyright Mob Telecom {new Date(Date.now()).getFullYear()}. Todos
              os direitos reservados. MOB PARTICIPAÇÕES S.A - CNPJ:
              07.100.988/0001-00
            </span>
          </div>
          <div />
        </div>
        <img
          className="footerLogoMob"
          src={logoFooterGrayLeft}
          alt="Logo Mob"
          style={{ position: "absolute", width: 100, height: 100, left: 10 }}
        />
        <img
          className="footerLogoMob"
          src={logoFooterGrayCenter}
          alt="Logo Mob"
          style={{ position: "absolute", width: 50, height: 50, left: "50%" }}
        />
        <img
          className="footerLogoMob"
          src={logoFooterRedRightBottom}
          alt="Logo Mob"
          style={{ position: "absolute", width: 150, height: 150, right: 10 }}
        />
      </div>
    </div>
  );
}
