import React, { useEffect, useState } from "react";
import Container from "components/Container/Container";

import InstitucionalHeader from "../InstitucionalHeader";
import Footer from "../footer/footer";
import ConditionalLayout from "components/ConditionalLayout/ConditionalLayout";
import LabelInfo from "components/LabelInfo/LabelInfo";

import ImageProfileEmpty from "assets/images/image-profile--empty.png";
import "./account.css";
import { logEvent } from "firebase/analytics";
import { analytics } from "utils/firebaseAnalytics";

const default_links = [
  {
    name: "Cartões",
  },
  {
    name: "Dados Pessoais",
  },
  {
    name: "Endereços",
  },
  {
    name: "Meus Pedidos",
  },
];

export default function MyAccount() {
  const [activeItem, setActiveItem] = useState<number>(1);

  useEffect(() => {
    logEvent(analytics, 'page_view', {
      page_location: window.location.pathname + window.location.search
    })
  }, [])

  return (
    <>
      <InstitucionalHeader />
      <Container id="myaccount" className="myaccount-account" background="#fff">
        <div className="myaccount-content">
          <div className="myaccount-sidebar">
            <div className="myaccount-profile--image">
              <img src={ImageProfileEmpty} alt="Profile image" />
              <span className="myaccount-profile--name">Olá, Lucas</span>
            </div>
            <h4 className="myaccount-content--title">Minha conta</h4>
            <div className="myaccount-profile--menu">
              <ul className="myaccount-menu--list">
                {default_links.map((item, index) => (
                  <li
                    key={item.name}
                    className={`myaccount-menu--item ${
                      index === activeItem ? "link--active" : ""
                    }`}
                  >
                    <button
                      onClick={() => setActiveItem(index)}
                      className="myaccount-menu--button"
                    >
                      {item.name}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="myaccount-profile--content">
            <ConditionalLayout isActive={activeItem === 0}>
              <div className="myaccount-cards"></div>
            </ConditionalLayout>
            <ConditionalLayout isActive={activeItem === 1}>
              <div className="myaccount-profile--edit">
                <div className="myaccount-card edit--profile">
                  <div className="myaccount-profile-card--info">
                    <div>
                      <LabelInfo title="Nome" text="Lucas" />
                      <LabelInfo
                        title="Data de Nascimento"
                        text="01 de Janeiro de 1984"
                      />
                      <LabelInfo title="CPF" text="999.999.999-99" />
                      <LabelInfo
                        title="E-mail"
                        text="lucas.silva@email.com.br"
                      />
                    </div>
                    <div style={{ marginLeft: 20 }}>
                      <LabelInfo title="Sobrenome" text="Silva" />
                      <LabelInfo title="Telefone" text="(99) 99999-9999" />
                      <LabelInfo title="Gênero" text="Masculino" />
                      <button className="myaccount-edit-button">Editar</button>
                    </div>
                  </div>
                </div>
                <div className="myaccount-profile-card--info myaccount-profile-card--password">
                  <LabelInfo title="Senha" text="**********" />
                  <button className="myaccount-edit-button">Editar</button>
                </div>
              </div>
            </ConditionalLayout>
            <ConditionalLayout isActive={activeItem === 2}>
              <div className="myaccount-address--edit"></div>
            </ConditionalLayout>
            <ConditionalLayout isActive={activeItem === 3}>
              <div className="myaccount-orders"></div>
            </ConditionalLayout>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
