import { GetProps } from "api/types";
import { api } from "../index";

export default async function Get({url, query}: GetProps) {
  try {
    const result = await api.get(url, {params: query});
    return result;
  } catch (error) {
    throw error;
  }
}