import React from 'react';

interface ConditionalLayoutProps {
    isActive: boolean;
    children: any;
}

export default function ConditionalLayout(props: ConditionalLayoutProps) {
    
    if (props.isActive) {
        return props.children
    }

    return <div></div>

}